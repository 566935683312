@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: 'Inter';
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #8504b8;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
